<template>
  <el-dialog
      top="30vh"
      width="450px"
      title="Chọn thông tin điểm danh"
      :visible.sync="isOpenExportTuitionFormDialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
  >

    <el-row style="margin-top: 10px">
      <el-col :offset="1" :span="4">
        <el-radio v-model="selectedOption" :label="true">File Excel</el-radio>
      </el-col>
      <el-col :offset="1" :span="4">
        <el-radio v-model="selectedOption" :label="false">Tháng được chọn</el-radio>
      </el-col>
    </el-row>

    <el-row
        :gutter="8" style="margin-top: 10px"
        v-if="!selectedOption"
    >
      <el-col
          :offset="1"
          :span="5"
          style="padding-top: 7px"
      >Chọn tháng
      </el-col>
      <el-col :span="2">
        <el-date-picker
            size="small"
            style="width: 120px"
            class="input-common"
            :clearable="false"
            v-model="monthAndYear"
            type="month"
            placeholder="Chọn ngày"
            value-format="M-yyyy"
            format="MM-yyyy"
        >
        </el-date-picker>
      </el-col>
    </el-row>

    <el-row style="margin-top: 10px; margin-right: 50px">
      <el-col style="display: inline-flex;flex-direction: row-reverse;gap: 0.8rem">
        <el-button type="danger" @click="handleClose"><i class="el-icon-circle-close"/> Đóng</el-button>
        <el-button
            type="success"
            icon="el-icon-download"
            :loading="loadingExcel"
            @click="submitOnParent"
        >Xuất File
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "ExportTuitionFormDialog",
  props: {
    isOpenExportTuitionFormDialog: {
      type: Boolean,
      default: false
    },
    isExportAllExcel:Boolean,
  },
  data() {
    return {
      monthAndYear: moment().format('MM-yyyy'),
      loadingExcel: false,
      selectedTemplate: '',
      timeout: null,
      selectedDescription: '',
      listFileTemplate: [],
      exportTemplateParams: {
        idTemplateSchool: "",
        monthNow: moment().format("M"),
        monthBeforeOrAfter: false,
        dataAfterMoney: false,
        idClass: "",
        idKidList: "",
        allTransaction: false,
        forwardTransaction: false,
        noForwardTransaction: false,
        year: moment().format("YYYY"),

      },
      selectedOption: true,
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeExportTuitionFormDialog", false)
    },
    submitOnParent() {
      if(!this.selectedOption){
        this.$emit('update:monthAndYear', this.monthAndYear);
      }
      this.isExportAllExcel === true ?
          this.$parent.xuatTheoThang(this.monthAndYear, this.selectedOption)
          : this.$parent.xuatTheoDotThu(this.monthAndYear, this.selectedOption);

      this.selectedOption = true;
    }
  }
}
</script>

<style scoped>

</style>