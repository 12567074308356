//mixin chủ yếu dùng các chức năng chung, (VD: validate data các con vk, .... )
export default {
  data(){
    return{
      listNullBillKids: [],
      listHiddenOrder:[],
      listShowForApp:[],
      listHiddenForApp:[]
    }
  },
  methods: {
    // xử lí dữ liệu học sinh
    validateNullBillStudent(row) {
      if(row.status==null){
        return true;
      }
      return false;
    },

    validateHiddenOrder(row){
      if(row.status=== 'Ẩn hóa đơn'){
        return true;
      }
      return false;
    },

    // xử lí dữ liệu nhân viên
    validateNullBillEmployee(row) {
      if(row.statusSalary==null){
        return true;
      }
      return false;
    },

    validateHiddenOrderEmployee(row){
      if(row.statusSalary=== 'Ẩn phiếu lương'){
        return true;
      }
      return false;
    },

  },
};
