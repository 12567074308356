export default new class CommonFunction{
     compareObjectLists(list1, list2) {
        if (list1.length !== list2.length) return false;
        for (let i = 0; i < list1.length; i++) {
            if (JSON.stringify(list1[i]) !== JSON.stringify(list2[i])) return false;
        }
        return true;
    }

    convertDate(dateStr) {
        const [month, year] = dateStr.split('-');
        const day = '15';
        return `${year}-${month.padStart(2, '0')}-${day}`;
    }
}