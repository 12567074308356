export default {
    data(){
      return{
          countColumnAttributes: 0,
      }
    },
    // Đếm số cột khoản thu
    methods:{
        countNonEmptyAttributes(listKT) {
            let count = 0;
            for (let i = 1; i <= 20; i++) {
                if (listKT[`nameKt${i}`] !== `Khoản thu ${i}`) {
                    count++;
                }
            }
            this.countColumnAttributes = count;
        },
    }
}